.pointer {
    cursor: pointer;
}

nav {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 0px;
    z-index: 99998;

    .navbar-content {
        display: none;
        align-items: center;
        justify-content: space-around;

        &,
        .nav-group,
        .nav-item,
        .logo {
            background: transparent !important;
        }

        .nav-group {
            display: flex;
            align-items: center;
            justify-content: center;

            .nav-item {
                &:not(:last-child) {
                    margin-right: 24px;
                }

                text-decoration: none;
            }
        }
    }

    .navbar-content-drawer {
        position: sticky;

        .logo {
            width: 120px;
            margin-left: -18px;
            margin-top: -10px;
        }

        .navbar-icon {
            font-size: 2rem;
            z-index: 10;
            margin-top: -12px;
        }

        .drawer-content {
            content: '';
            width: 0;
            height: 100vh;
            background: rgb(52, 52, 52);
            position: absolute;
            top: 0;
            right: 0;
            transition: width 1s ease-in-out;
            transition-delay: .3s;
            z-index: 9;

            .nav-item-drawer {
                margin-bottom: 20px;
                text-decoration: none;
                font-size: 1.5rem;
                opacity: 1;
                transform: translateX(300px);
                transition: opacity 1s ease-in-out, transform 2s ease-in-out;

                &:nth-child(3),
                &:nth-child(5) {
                    margin-bottom: 0px;
                }
            }

            .drawer-separator {
                content: '';
                display: block;
                width: 50%;
                height: 1px;
                background: rgb(181, 181, 181);
                margin: 20px auto;
            }

            &.active {
                width: 100vw;
                transition: width 1s ease-in-out;

                .nav-item-drawer {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
        }

    }
}

.product-group-card {
    padding: 30px;
    border: 1px $dark-blue solid;
    transform: scale(1);
    transition: transform .5s ease-in-out;
    position: relative;
    margin-bottom: 100px;

    & .group-title {
        position: absolute;
        font-size: 1.5rem;
        padding: 0px;
        margin: 10px 20px;
        transition: font-size .5s ease-in-out;

        &:last-child {
            bottom: 0px;
            right: 0px;
        }
    }

    .product {
        &-img {
            img {
                width: 120px;
            }

        }

        &-content {
            h1 {
                font-size: 2rem;
                font-weight: bolder;
            }

            p {
                margin-bottom: 1rem;
            }
        }
    }

    &:hover {
        transform: scale(1.05);

        & .group-title {
            font-size: 2rem;
            transition: font-size .5s ease-in-out;
        }

    }
}

.btn {
    width: fit-content;
    padding: 10px 20px;
    border: solid 1px black;
    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    transition: background .7s ease-in-out;
    font-size: 1rem;

    &.cta-btn {
        font-size: 1.7rem;
    }

    &:hover {
        background: $orange-yellow;
    }
}

.input {
    width: 50%;
    background: transparent !important;
    color: $dark-blue;
    border: none;
    border-bottom: 3px solid $primary-color;
    text-align: center;
    font-size: 1.3rem;
    padding: 0px;
    outline-color: transparent;
    transition: font-size .7s ease-in-out, padding .7s ease-in-out, outline-color .7s ease-in-out;

    &:focus {
        outline-color: $dark-blue;
        font-size: 2rem;
        padding: 15px 10px;
    }
}

.flex {
    display: flex;
    flex-direction: column;

    &-direction {
        &-column {
            flex-direction: column;
        }

        &-row {
            flex-direction: row;
        }
    }

    &.align {
        &-center {
            align-items: center;
        }

        &-end {
            align-items: flex-end;
        }

        &-start {
            align-items: flex-start;
        }
    }

    &.justify {
        &-center {
            justify-content: center;
        }

        &-around {
            justify-content: space-around;
        }

        &-between {
            justify-content: space-between;
        }

        &-evenly {
            justify-content: space-evenly;
        }
    }
}


.send-btn {
    margin-left: 20px;
    font-size: 0px;
    transition: all .3s ease-in;

    &.active {
        font-size: 34px;
    }
}



.row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .col {
        &.xs {
            @for $i from 1 through 12 {
                &-#{$i} {
                    grid-column: span $i;
                }
            }
        }

        @media (min-width:576px) {
            &.sm {
                @for $i from 1 through 12 {
                    &-#{$i} {
                        grid-column: span $i;
                    }
                }
            }
        }

        @media (min-width:768px) {
            &.md {
                @for $i from 1 through 12 {
                    &-#{$i} {
                        grid-column: span $i;
                    }
                }
            }
        }

        @media (min-width:992px) {
            &.lg {
                @for $i from 1 through 12 {
                    &-#{$i} {
                        grid-column: span $i !important;
                    }
                }
            }
        }

        @media (min-width:1200px) {
            &.xl {
                @for $i from 1 through 12 {
                    &-#{$i} {
                        grid-column: span $i !important;
                    }
                }
            }
        }
    }
}

.no {
    &-padding {
        &-top {
            padding-top: 0px !important;
        }

        &-right {
            padding-right: 0px !important;
        }

        &-bottom {
            padding-bottom: 0px !important;
        }

        &-left {
            padding-left: 0px !important;
        }
    }

    &-margin {
        &-top {
            margin-top: 0px !important;
        }

        &-right {
            margin-right: 0px !important;
        }

        &-bottom {
            margin-bottom: 0px !important;
        }

        &-left {
            margin-left: 0px !important;
        }
    }
}

@for $i from 1 through 150 {
    .padding {
        &-top {
            &-#{$i} {
                padding-top: #{$i}px;
            }
        }

        &-right {
            &-#{$i} {
                padding-right: #{$i}px;
            }
        }

        &-bottom {
            &-#{$i} {
                padding-bottom: #{$i}px;
            }
        }

        &-left {
            &-#{$i} {
                padding-left: #{$i}px;
            }
        }
    }

    .margin {
        &-top {
            &-#{$i} {
                margin-top: #{$i}px;
            }
        }

        &-right {
            &-#{$i} {
                margin-right: #{$i}px;
            }
        }

        &-bottom {
            &-#{$i} {
                margin-bottom: #{$i}px;
            }
        }

        &-left {
            &-#{$i} {
                margin-left: #{$i}px;
            }
        }
    }
}


@media(min-width:576px) {
    nav {
        .logo {
            width: 150px;
        }

        .navbar-content-drawer {
            display: none;
        }

        .navbar-content {
            display: flex;
        }
    }

    .product-group-card {
        padding: 70px;

        .product {
            &-img {
                img {
                    width: 150px;
                }
            }

            &-content {
                padding: 40px;
                border-left: 1px black solid;
            }
        }

    }
}

.link-content {
    .link {
        font-size: 1.5rem;

        margin-bottom: 10px;
        cursor: pointer;
        position: relative;

        &.no-default {
            font-size: 1rem;
            margin: 0;

            &::after {
                height: 1px;
            }
        }

        &::after {
            content: '';
            display: block;
            height: 3px;
            width: 0;
            transform: translate(0, -50%);
            background: rgb(37, 37, 37);
            position: absolute;
            top: calc(100%/2);
            right: 0;
            transition: width .5s ease-in-out;
        }

        &:hover {
            &::after {
                width: 70%;
            }
        }

        &.active {
            &::after {
                width: 70%;
            }
        }
    }
}