@import './variables';
@import './common';

html{
    scroll-behavior: smooth;
}
html,
body,
* {
    padding: 0;
    margin: 0;
    font-family: 'Amiri', serif;
    //font-family: 'DM Serif Text', serif;
    //font-family: 'Montserrat', sans-serif;
    color: #707070;
}

html {
    //background: $bg-color;
    overflow-x: hidden;
}

body {
    overflow-x: hidden;
}

.bg-normal {
    background: $bg-color;
    transition: background 1s ease-in-out;
}

.bg-day {
    background: #fadc46;
    transition: background 1s ease-in-out;
}

.bg-night {
    background: #b0dde1;
    transition: background 1s ease-in-out;
}

.bg-low-product {
    background: $low-product-color;
    transition: background 1s ease-in-out;
}

.bg-medium-product {
    background: $medium-product-color;
    transition: background 1s ease-in-out;
}

.bg-high-product {
    background: $high-product-color;
    transition: background 1s ease-in-out;
}

.container {
    max-width: 100%;
    padding: 0px 10%;
}

.img-p {
    height: 200px;

    &.day-night {}

}

section {
    text-transform: initial;

    .title {
        font-size: 2rem;
        margin-bottom: 16px;
        line-height: 4rem;

        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }

        &.big {
            font-size: 4rem;
        }
    }

    .subtitle {
        font-size: 2rem;
    }

    .content {
        font-size: 1rem;
        text-align: justify;
    }

    .sub-content {
        font-size: 1rem;
        text-align: justify;
        margin-bottom: 16px;
        width: fit-content;
    }
}


/* Header */
header {
    width: 100%;
    height: 100vh;

    h1 {
        font-size: 3em;
        line-height: 3rem;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .header-products {
        position: absolute;
        width: 110px;

        &-1 {
            top: 510px;
            right: 200px;
        }

        &-2 {
            top: 490px;
            right: 105px;
        }

        &-3 {
            top: 510px;
            right: 10px;
        }
    }

}

.investment {
    width: 100%;
    height: 66vh;
    padding-top: 180px;
}

/* Benefits */
.benefits {
    width: 100%;
    height: 100%;

    &-content-group{
        margin-bottom: 30px;
        margin-top: 30px;
        .icons{
            font-size: 4rem;
        }
    }

    @media screen and (max-width:576px) {
        .problems {
            flex-direction: column;
            align-items: center
        }
    }

}

/* Products */
.products {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
}

/* Testimonials */
.testimonials {
    width: 100%;
    height: 50vh;
    padding-bottom: 1.5rem;

    span {
        margin-top: -50px;
        position: absolute;
        font-weight: 300;
        max-width: 60%;
        opacity: 0;
        font-size: 1.5rem;
        background: transparent !important;
        -webkit-animation: slide-in-right 20s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;
        animation: slide-in-right 36s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;

        .testimonial {
            font-size: .8rem;
            text-transform: capitalize;
            font-style: oblique;
        }

        .author {
            font-size: 1rem;
            text-align: end;
        }

        @media(min-width:500px) {
            .testimonial {
                font-size: 1.2rem;
            }

            .author {
                font-size: 1rem;
            }
        }

        &:nth-child(2) {
            animation-delay: 6s;
        }
        &:nth-child(3) {
            animation-delay: 12s;
        }
        &:nth-child(4) {
            animation-delay: 18s;            
        }

        &:nth-child(5) {
            animation-delay: 24s;
        }
        &:nth-child(6) {
            animation-delay: 30s;
        }
    }

/*     @-webkit-keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(10px);
            transform: translateX(10px);
            opacity: 0;
        }

        25% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }

        50% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }

        75% {
            -webkit-transform: translateX(1000px);
            transform: translateX(10px);
            opacity: 0;
        }

        100% {
            -webkit-transform: translateX(1000px);
            transform: translateX(10px);
            opacity: 0;
        }
    } */


    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 0;
        }

        8% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }

        17% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }

        25% {
            opacity: 0;
        }
/* 
        75% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 0;
        } */

        100% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 0;
        }
    }

}

/* Contact us */
.contact-us {
    width: 100%;
    height: 33vh;
}

footer {
    //width:100%;
    height: fit-content;
    position: relative;
    padding: 35px 0px 15px 0px;
    background: rgb(61, 61, 61);
    *{
        color:rgb(187, 187, 187);
    }

    .logo{
        width: 170px;
    }

    .footer-content {
        .title-decoration{
            &::after{
                content: "";
                display: block;
                width: 100%;
                background: rgb(187, 187, 187);;
                height: 1px;
                position: relative;
                top: calc(100%/2);
            }
        }
        .social-icons{
            .icons{
                font-size: 1.5rem;
                margin-top: 10px;
                &:last-child{
                    margin-left: 26px;
                }
            }
        }
        .social-decoration{
            margin: 30px 0px;
            height: 70px;
            width: 1px;
            display: inline-block;
            background: rgb(187, 187, 187);;

        }
        .footer-contact{
            input{
                width: 100%;
                color: rgb(187, 187, 187);
            }
        }
    }

    .footer-leyends,
    .footer-content {
        z-index: 9999;
        position: relative;
    }
    .footer-leyends{
        padding-top: 10px;
        border-top: 1px solid rgb(187, 187, 187);
    }
}


/* Our products */
.our-products {
    width: 100%;
    padding-top: 5rem;
    height: fit-content;
    transition: background 1s ease-in-out;

    &.dynamic-bg {
        background: yellow;
        transition: background 1s ease-in-out;
    }
}

.product-card {
    width: 100%;
    margin-bottom: 50px;

    .image {
        width: 100%;
    }

    .product {
        &-title {}

        &-content {}

        &-price {}
    }
}


.our-story {
    width: 100%;
    height: fit-content;
    padding-top: 7rem;
    padding-bottom: 7rem;

    h3 {
        font-size: 1.4rem;
        margin-bottom: 20px;
    }

    p {
        font-size: 1rem;
        margin-bottom: 2rem;
        text-align: justify;
    }
}





@media (min-width:576px) {
    section {
        .title {
            font-size: 2.5rem;
            margin-bottom: 16px;
            line-height: 3rem;
        }

        .subtitle {
            font-size: 2rem;
        }

        .content {
            font-size: 2rem;
        }

        .sub-content {
            font-size: 1.5rem;
            margin-bottom: 16px;
        }
    }

    .container {
        padding: 0px 15%;
    }

    header {
        h1 {
            font-size: 5em;
            line-height: 5rem;
            margin-bottom: 10px;
        }

        p {
            font-size: 2rem;
            margin-bottom: 10px;
        }

        .header-products {
            position: absolute;
            width: 100px;

            &-1 {
                top: 600px;
                right: 280px;
            }

            &-2 {
                top: 570px;
                right: 460px;
            }

            &-3 {
                top: 600px;
                right: 640px;
            }
        }

    }

    section {    
        .title {
            line-height: 3rem;
        }
    
    }


}

@media (min-width:768px) {

    .benefits{
        height: 50vh;
    }

    .investment {
        padding: 0;
    }

    .products{
        padding-top: 0px;
        margin-bottom: 10px;
        height: 66vh;
    }

    .img-p {
        height: 300px;
        &.day-night {
            animation: move 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;

            @keyframes move {
                0% {
                    transform: rotate(15deg);
                }

                50% {
                    transform: rotate(25deg);
                }

                100% {
                    transform: rotate(15deg);
                }
            }

            &:last-child {
                padding-top: 30px;
                margin-left: 50px;
                animation: move 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;
            }
        }

    }
    .our-story {
        width: 100%;
        height: 165vh;
        padding:0px;
    
        h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
    
        p {
            font-size: 1.3rem;
        }
    }

    .testimonials{
        height: 30vh;
    }
}