@import url('https://fonts.googleapis.com/css?family=Amiri:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=DM+Serif+Text:400,400i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap');

$bg-color: #EDEDE7;
$primary-color: #CCE2A2;
$secondary-color: #ACDACD;
$orange-yellow:#FDE29D;
$dark-blue:#555B6E;
$light-blue:#89B0AE;

$low-product-color:#a9c4bc;
$medium-product-color:#b3c697;
$high-product-color:#c5b895;